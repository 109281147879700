<template>
  <b-modal
    id="ModalUpdateImageBrand"
    hide-footer
    no-close-on-backdrop
    hide-header-close
    scrollable
    size="lg"
    hi
  >
    <template #modal-header="">
      <div class="row w-100">
        <div class="col-10">
          <h5>
            {{ datainput.mode == "add" ? "Add Brand" : "Edit Brand" }}
          </h5>
        </div>
        <div class="col-2 text-right">
          <span type="button" @click="cancel()">
            X
          </span>
        </div>
      </div>
    </template>
    <div class="row form-inline">
      <div class="col-12 col-lg-2 mb-4 text-right">ภาพแบรนด์</div>
      <div class="col-12 col-lg-10 mb-4">
        <div class="row">
          <div class="col-12">
            <div class="input-group" v-if="selectedImage">
              <img
                :src="data.brandImage"
                width="200px"
                height="200px"
                alt="Image"
                class="img-thumbnail img-responsive"
              />
            </div>
            <div class="input-group" v-else-if="data.brandImage">
              <img
                :src="pathURL + data.brandImage"
                width="200px"
                height="200px"
                alt="Image"
                class="img-thumbnail img-responsive"
              />
            </div>
            <div class="input-group" v-else>
              <img
                src="@/assets/photo.png"
                width="200px"
                height="200px"
                alt="Image"
                class="img-thumbnail img-responsive"
              />
            </div>
          </div>
          <div class="col-12">
            <input type="file" @change="handleFileUpload" />
          </div>
        </div>
      </div>
    </div>
    <div class="row w-100">
      <div class="col-12 col-lg-2 mb-4 text-right mt-1">ชื่อแบรนด์</div>
      <div class="col-12 col-lg-10 mb-4">
        <div class="row">
          <div class="col-10">
            <div class="input-group" v-if="datainput.mode == 'add'">
              <multiselect
                v-model="data.brandName"
                :options="BrandList"
                :clear-on-select="true"
                :allow-empty="true"
                deselect-label=""
                select-label=""
                selected-label=""
              ></multiselect>
            </div>
            <div class="input-group" v-else>
              <input
                type="text"
                class="form-control"
                maxlength="200"
                v-model="data.brandName"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-2 mb-4 text-right ">สถานะ</div>
      <div class="col-12 col-lg-10 mb-4">
        <div class="row">
          <div class="col-10">
            <div
              class="form-check form-check-inline"
              v-for="(e, i) in active"
              :key="i"
            >
              <input
                class="form-check-input"
                type="radio"
                :id="e.name"
                :value="e.key"
                v-model="data.active"
              />
              <label class="form-check-label" :for="e.name">{{ e.name }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-12 text-right  mb-3">
        <button type="button" class="btn bt-main" @click="save()">
          บันทึก
        </button>
        <button type="button" class="btn btn-default" @click="cancel()">
          ยกเลิก
        </button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { API_IMG_UPLOAD } from "@/services/constant";
export default {
  name: "ModalUpdateImageBrand",
  components: {},
  props: ["datainput"],
  data() {
    return {
      pathURL: API_IMG_UPLOAD,
      selectedImage: null,
      BrandList: [],
      data: {
        brandId: null,
        brandName: null,
        brandImage: null,
        active: 1,
      },
      active: [
        { key: 1, name: "Active" },
        { key: 0, name: "InActive" },
      ],
    };
  },
  watch: {},
  computed: {},
  mounted() {
    if (this.datainput.mode == "add") {
      this.getPimImageBrandSelectList();
    } else {
      this.setDataImageBrand();
    }
  },
  methods: {
    async setDataImageBrand() {
      try {
        if (this.datainput.data) {
          const {
            brandId,
            brandName,
            brandImage,
            active,
          } = this.datainput.data;
          this.data = {
            brandId: brandId,
            brandName: brandName,
            brandImage: brandImage,
            active: active,
          };
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async getPimImageBrandSelectList() {
      try {
        let getAPI = await this.$serviceAPI.call_API(
          "get",
          `manage/getPimImageBrandSelectList`,
          {},
          1
        );
        var selected = [];
        for (var key in getAPI.data) {
          selected.push(getAPI.data[key].brandName);
        }
        this.BrandList = selected;
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async cancel() {
      this.$bvModal.hide("ModalUpdateImageBrand");
      this.selectedImage = null;
      this.data = {
        brandId: null,
        brandName: null,
        brandImage: null,
        active: 1,
      };
    },
    async save() {
      try {
        //check valdidate
        if (!this.selectedImage && !this.data.brandImage)
          throw new Error("กรุณาอัพโหลดไฟล์");
        if (!this.data.brandName) throw new Error("กรุณาเลือกแบรนด์");

        if (this.selectedImage) {
          const formData = new FormData();
          formData.append("file", this.selectedImage);
          try {
            let saveimg = await this.$serviceAPI.call_API_FormData(
              "post",
              `upload/brand`,
              formData,
              0
            );
            if (saveimg.data) {
              this.data.brandImage = saveimg.data.path;
              let data = { datas: this.data };
              let getAPI = await this.$serviceAPI.call_API(
                "post",
                `manage/updatePimImageBrand`,
                data,
                1
              );
              if (getAPI.data[0].MSG == "OK") {
                this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
              }
            }
          } catch (error) {
            this.$serviceMain.showErrorAlert(
              this,
              error.message == undefined ? error : error.message
            );
          }
        } else {
          let data = { datas: this.data };
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `manage/updatePimImageBrand`,
            data,
            1
          );
          if (getAPI.data[0].MSG == "OK") {
            this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
          }
        }
        await this.cancel();
        this.$emit("getPimImageBrand");
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async handleFileUpload(event) {
      try {
        const file = event.target.files[0];
        if (file) {
          if (
            file.type !== "image/jpeg" &&
            file.type !== "image/png" &&
            file.type !== "image/jpg"
          ) {
            throw `Support .png, .jpg, .jpeg only`;
          }
          this.selectedImage = file;
          this.data.brandImage = URL.createObjectURL(file);
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
  },
};
</script>
<style scoped>
.img {
  width: auto;
  height: 10vh;
}
</style>
