<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 p-0 shadow mb-5 bg-white rounded">
        <div class="row m-0 p-3">
          <div class="col-12 col-xl-6 mx-0 mb-3 mb-xl-0">
            <p class="font-1-2s font-600">Manage Image Brand</p>
          </div>
          <div class="col-12 col-xl-6 text-right">
            <button class="btn  bt-main mx-0 mb-3 mb-xl-0" @click="addBrand()">
              <span v-html="$svg_icon.add"></span> Add Brand
            </button>
          </div>
        </div>
        <hr class="m-0 p-0" />
        <div class="row m-0 p-4">
          <div class="col-12 col-xl-6">
            <div class="input-group input-group-sm  mb-3">
              <div class="input-group-prepend input-group-sm  ">
                <select class="custom-select" v-model="mode">
                  <option v-for="(e, i) in select_mode" :key="i" :value="e.key">
                    {{ e.name }}
                  </option>
                </select>
              </div>
              <input
                type="search"
                class="form-control form-control-sm"
                placeholder="ค้นหา / Seach"
                v-model="find"
                v-on:keyup.enter="Search()"
              />
              <div class="input-group-append">
                <button class="btn bt-main btn-sm" @click="Search()">
                  ค้นหา
                </button>
              </div>
            </div>
          </div>
          <div class="col-12">
            <p style="font-weight: bold;">
              จำนวน&nbsp;
              <span class="text-main">{{
                new Intl.NumberFormat().format(totalRows)
              }}</span>
              &nbsp;รายการ
            </p>
          </div>
          <div class="col-12">
            <b-table
              :empty-text="'ไม่พบข้อมูล / No Data'"
              :fields="fields"
              :items="items"
              :current-page="page_num"
              :per-page="page_size"
              outlined
              hover
              show-empty
              responsive
              class="font-0-8s"
            >
              <template #cell(brandImage)="row">
                <img
                  v-if="row.item.brandImage"
                  class="img-thumbnail img-responsive"
                  :src="pathURL+row.item.brandImage"
                  alt=""
                  width="70"
                  height="70"
                />
                <img
                  v-else
                  class="img-thumbnail img-responsive"
                  src="@/assets/photo.png"
                  alt=""
                  width="70"
                  height="70"
                />
              </template>
              <template #cell(active)="row">
                <span
                  :class="[row.item.active == '1' ? 'badge-main' : 'badge-twd']"
                  class="badge"
                  >{{ row.item.active == 0 ? "No" : "Yes" }}</span
                >
              </template>
              <template #cell(action)="row">
                <span
                  v-html="$svg_icon.edit"
                  type="button"
                  class="mr-3 text-muted"
                  @click="editBrand(row.item)"
                ></span>
              </template>
            </b-table>
          </div>
          <div class="col-12">
            <div class="row justify-content-end">
              <div class="col-6 col-xl-1 input-group-prepend input-group-sm">
                <select class="custom-select" v-model="page_size">
                  <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                    {{ e }}
                  </option>
                </select>
              </div>
              <div class="col-6 col-xl-3">
                <b-pagination
                  v-model="page_num"
                  :total-rows="totalRows"
                  :per-page="page_size"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalUpdateImageBrand
      :key="reModal"
      :datainput="dataModal"
      @getPimImageBrand="getPimImageBrand"
    />
  </div>
</template>

<script>
import { API_IMG_UPLOAD } from "@/services/constant";
import ModalUpdateImageBrand from "../../components/Manage/ImageBrand/ModalUpdateImageBrand.vue";
export default {
  name: "ManageImageBrand",
  components: {
     ModalUpdateImageBrand
  },
  data() {
    return {
      pathURL: API_IMG_UPLOAD,
      reModal: Date.now() + 150,
      dataModal: { mode: "", data: null },
      data_user: "",
      find: "",
      page_size: 10,
      page_size_list: [10, 15, 20, 25, 30],
      page_num: 1,
      totalRows: 1,
      mode: "brandName",
      select_mode: [{ name: "ค้นหา Name", key: "brandName" }],
      fields: [
        { key: "No", label: "No.", class: "text-center" },
        { key: "brandName", label: "Name", class: "text-left" },
        { key: "brandImage", label: "Image" },
        { key: "active", label: "active", class: "text-center" },
        { key: "updateDate", label: "Last Update" },
        { key: "updateBy", label: "updateBy" },
        {
          key: "action",
          label: "",
          class: "text-right",
          thStyle: { width: "8%" },
        },
      ],
      items: [],
      itemlist: [],
    };
  },
  watch: {
    page_num: function() {},
    find: function() {
      if (this.find == "") {
        this.unSearch();
      }
    },
  },
  computed: {},
  mounted() {
    this.getPimImageBrand();
  },
  methods: {
    unSearch() {
      this.items = this.itemlist;
      this.totalRows = this.items.length;
    },
    Search() {
      var results = [];
      if (this.find == "") {
        this.getPimImageBrand();
      } else {
        let find = this.find;
        if (this.mode !== "active") {
          find = this.find;
        } else {
          find =
            String(this.find).toUpperCase() == "YES"
              ? 1
              : String(this.find).toUpperCase() == "NO"
              ? 0
              : this.find;
        }
        var toSearch = String(find)
          .trim()
          .toLocaleUpperCase()
          .replace(/ /g, "");
        var objects = this.itemlist;
        for (var i = 0; i < objects.length; i++) {
          var index = String(objects[i][this.mode])
            .trim()
            .toLocaleUpperCase()
            .replace(/ /g, "");
          if (index.indexOf(toSearch) != -1) {
            results.push(objects[i]);
          }
          // }
        }
        this.items = results;
        this.totalRows = this.items.length;
      }
    },
    async getPimImageBrand() {
      try {
        let data = {};
        let getAPI = await this.$serviceAPI.call_API(
          "get",
          `manage/getPimImageBrand`,
          data,
          1
        );
        this.items = getAPI.data;
        this.itemlist = getAPI.data;
        this.totalRows = getAPI.data.length;
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    addBrand() {
      this.reModal = Date.now();
      this.dataModal.mode = "add";
      this.dataModal.data = null;
      setTimeout(() => {
        this.$bvModal.show("ModalUpdateImageBrand");
      }, 100);
    },
    editBrand(data) {
      this.reModal = Date.now();
      this.dataModal.mode = "edit";
      this.dataModal.data = data;
      setTimeout(() => {
        this.$bvModal.show("ModalUpdateImageBrand");
      }, 100);
    },
  },
};
</script>
